import React from "react";
import { ExInput, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Table from "../../extras/Table";

const ServiceInfo = () => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);

  console.log('dialogueData', dialogueData)

  const salonRequestTable = [
    {
      Header: "No",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "Image",
      Cell: ({ row }) => (
        <div className="userProfile">
          <img
            src={
               row?.id?.image 
            }
            alt="image"
            className="cursor-pointer"
            style={{ height: "70px", width: "70px", overflow: "hidden" }}
            // onClick={() => openImage(row?.image)}
            height={`100%`}
            // onError={(e) => {
            //   e.target.src = Male;
            // }}
          />
        </div>
      ),
    },


    {
      Header: "Name",
      Cell: ({ row }) => <span className="text-capitalize">{row?.id?.name}</span>,
    },

    {
      Header: "Price",
      Cell: ({ row }) => <span className="text-capitalize">{row?.price}</span>,
    },

    {
      Header: "Duration",
      Cell: ({ row }) => <span className="text-capitalize">{row?.id?.duration}</span>,
    },

  ];


  return (
    // <div className="dialog focusNone mainDiaogBox">
    //   <div class="w-100">
    //   <Table data={dialogueData} mapData={salonRequestTable} />
    //   </div>
    // </div>
    <div className="dialog focusNone">
    <div class="w-100">
      <div class="row justify-content-center">
        <div class="col-xl-4 col-md-6 col-11">
          <div class="mainDiaogBox">
            <div class="row justify-content-between align-items-center formHead">
              <div className="col-8">
                <h2 className="text-theme m0">Service info</h2>
              </div>
              <div className="col-4">
                <div
                  className="closeButton"
                  onClick={() => {
                    dispatch(closeDialog());
                  }}
                >
                  <i className="ri-close-line"></i>
                </div>
              </div>
            </div>
            <div className="mt-3">
            
               <Table data={dialogueData?.serviceIds} mapData={salonRequestTable} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default ServiceInfo;
